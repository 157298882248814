import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { ScrollArea, Stack } from '@mantine/core';

import { getHasUserLinkedRYP } from '~/domains/oauth/utils/getAccessTokenData';

import { ContestList } from './shared/ContestList';
import { MOBILE_SECTION_HEIGHT } from './consts';

type ContestPanelProps = {
  selectedItem: string;
  setSelectedItem: Dispatch<SetStateAction<string>>;
  setSelectedMobileTab: Dispatch<SetStateAction<string>>;
  children: ReactNode;
};

function ContestsPanel({
  selectedItem,
  setSelectedItem,
  children,
  setSelectedMobileTab,
}: ContestPanelProps) {
  const hasUserLinkedRYP = getHasUserLinkedRYP();
  return (
    <Stack mt="lg" h={MOBILE_SECTION_HEIGHT}>
      <ScrollArea h="100%">
        <ContestList
          showRYPAccordion={hasUserLinkedRYP}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          setSelectedMobileTab={setSelectedMobileTab}
        />
      </ScrollArea>
      {children}
    </Stack>
  );
}

export default ContestsPanel;
