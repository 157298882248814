import React, { useCallback, useContext, useMemo } from 'react';
import { Loader, LoadingOverlay, Skeleton } from '@mantine/core';
import { useTheme } from '@emotion/react';
import type { EntriesDetails, ContestStatus } from '@betterpool/api-types/contests-service';
import useTranslation from 'next-translate/useTranslation';

import { UserContext } from '~/components/providers/UserProvider';
import { Tooltip } from '~/domains/common/components/Tooltip/Tooltip';
import useEnterContestButton from '~/domains/contest/domains/common/hooks/useEnterContestButton';
import dt from '~/testing';
import { Button } from '~/domains/design-system/Button';

type EntryButtonProps = {
  onSuccess?: (entryIDs?: string[]) => Promise<void> | void;
  className?: string;
  contestId: string;
  isAfterDeadline: boolean;
  entries: EntriesDetails;
  isAllowedInLocation?: boolean;
  status: ContestStatus;
  onClick?: (cb: () => void) => void;
  fullWidth?: boolean;
  children?: React.ReactElement;
  isSpectatorMode?: boolean;
};

function EntryButton({
  onSuccess,
  className,
  contestId,
  isAfterDeadline,
  entries,
  isAllowedInLocation,
  status,
  onClick,
  fullWidth = false,
  children,
  isSpectatorMode = false,
}: EntryButtonProps) {
  const theme = useTheme();
  const { t } = useTranslation('contest');
  const { user } = useContext(UserContext);

  const {
    error,
    errorMessage,
    isDisabled,
    isLoading,
    onClick: onButtonClick,
  } = useEnterContestButton({
    contestId,
    isAfterDeadline,
    entries,
    isAllowedInLocation,
    status,
  });

  const handleOnClick = useCallback(
    (bypassCallback = false) => {
      if (!bypassCallback && typeof onClick === 'function')
        return onClick(() => handleOnClick(true));
      // Do not hate me
      // First onClick argument is a legacy callback that will skip createEntry if current page is gameshell
      // This is an artefact of anonymous picks and touching it might introduce regression...
      if (onSuccess) return onButtonClick(onSuccess);
      return onButtonClick();
    },
    [onButtonClick, onClick, onSuccess]
  );

  const actionButtonContent = useMemo(() => {
    if (isLoading) return <Loader stroke={theme.white} size="sm" />;
    if (!user) {
      return t('detail.header.actions.makePicks');
    }

    return t('detail.header.actions.new__hasPlural', {
      count: entries.max_per_user,
    });
  }, [isLoading, theme.white, user, t, entries.max_per_user]);

  if (isSpectatorMode) return null;

  return (
    <Skeleton
      visible={user && isLoading}
      h={children ? undefined : 40}
      w="auto"
      className={className}
    >
      <LoadingOverlay visible={user && isLoading} loaderProps={{ size: 'sm' }} />
      <Tooltip
        withArrow
        withinPortal
        isRendered={isDisabled && !!error}
        labels={[
          {
            label: errorMessage,
          },
        ]}
      >
        <div>
          {children ? (
            React.cloneElement(children, {
              onClick: (event: React.MouseEvent) => {
                if (typeof children.props.onClick === 'function') {
                  children.props.onClick(event);
                }

                handleOnClick();
              },
            })
          ) : (
            <Button
              className={className}
              data-test-id={dt.contestDetail.page.header.actions.new}
              onClick={() => handleOnClick()}
              disabled={isDisabled}
              variant="primary-fill"
              size="medium"
              fullWidth={fullWidth}
            >
              {actionButtonContent}
            </Button>
          )}
        </div>
      </Tooltip>
    </Skeleton>
  );
}

export default EntryButton;

export type { EntryButtonProps };
