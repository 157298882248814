import { Accordion, Loader, Stack, Text } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import type { Dispatch, SetStateAction } from 'react';

import useFetchSplashContests from '../../hooks/useFetchSplashContests';

import ContestAccordionPanel from './ContestAccordionPanel';

type ContestListProps = {
  selectedItem: string;
  setSelectedItem: Dispatch<SetStateAction<string>>;
  setSelectedMobileTab?: Dispatch<SetStateAction<string>>;
  showRYPAccordion: boolean;
};

function ContestList({
  selectedItem,
  setSelectedItem,
  showRYPAccordion,
  setSelectedMobileTab,
}: ContestListProps) {
  const { t } = useTranslation('common');

  const { isInitialLoading } = useFetchSplashContests();

  return (
    <Stack w="100%" spacing={2} h="100%">
      <Text transform="uppercase" color="dimmed" size={12}>
        {t('invites.modal.previousContests')}
      </Text>
      {isInitialLoading && <Loader />}
      {!isInitialLoading && (
        <Accordion defaultValue="splash">
          <ContestAccordionPanel
            value="Splash"
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            setSelectedMobileTab={setSelectedMobileTab}
          />
          {showRYPAccordion && (
            <ContestAccordionPanel
              value="RYP"
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              setSelectedMobileTab={setSelectedMobileTab}
            />
          )}
        </Accordion>
      )}
    </Stack>
  );
}

export { ContestList };
