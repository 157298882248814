import type { ModalProps } from '@mantine/core';
import { Button, Divider, Group, Modal, Stack, Text, createStyles } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import type { MutableRefObject } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showNotification } from '@mantine/notifications';
import type { AxiosError } from 'axios';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';

import { safeJSONParse } from '~/common/utils/safeJSONParse.util';
import { UserContext } from '~/components/providers/UserProvider';
import { reportEvent } from '~/domains/analytics';
import type { EntrantUser } from '~/domains/contest/domains/services/interfaces/entrant.interface';
import { sendInvites } from '~/services/users/users.service.api';

import useFetchUsersByContest from '../hooks/useFetchUsersByContest';
import useFetchRYPUsersByContest from '../hooks/useFetchRYPUsersByContest';

import InviteModalMiddle from './InviteModalMiddle';
import { InviteModalRight } from './InviteModalRight';
import { InviteModalLeft } from './InviteModalLeft';
import InviteHeader from './InviteHeader';
import PeoplePanel from './PanelPeople';
import ContestsPanel from './PanelContests';
import EmailPanel from './PanelEmail';
import TabletDrawer from './TabletDrawer';
import { SECTION_HEIGHT } from './consts';

type InviteModalProps = ModalProps & {
  contestId?: string;
  contestName?: string;
  emailOnly?: boolean;
  onSuccess?: VoidFunction;
};

const useStyles = createStyles((theme) => ({
  panelWrapper: {
    position: 'relative',
    width: '100%',
  },
  tabletFloatingButton: {
    position: 'absolute',
    bottom: '80px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: theme.colors.dark[6],
    color: theme.white,
    padding: theme.spacing.sm,
    borderRadius: theme.radius.md,
    left: '50%',
    transform: 'translateX(-50%)',
  },
}));

function InviteModal({
  opened = true,
  onClose,
  contestId,
  contestName,
  emailOnly,
  onSuccess,
}: InviteModalProps) {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.md });
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedMobileTab, setSelectedMobileTab] = useState('people');
  const [emailInvites, setEmailInvites] = useState([]);
  const [splashInvites, setSplashInvites] = useState([]);
  const [contests, setContests] = useState([]);
  const [rypPools, setRYPPools] = useState([]);
  const [rypInvites, setRYPInvites] = useState([]);
  const rawTotalInvites =
    emailInvites.length +
    splashInvites.length +
    rypInvites.length +
    contests.length +
    rypPools.length;
  const totalInvites = Number.isNaN(rawTotalInvites) ? 0 : rawTotalInvites;
  const { classes } = useStyles();
  const { user: currentUser } = useContext(UserContext);
  const queryClient = useQueryClient();
  const inviteLinkURLOrigin =
    window?.location?.hostname !== 'localhost'
      ? window.location.origin
      : 'https://dev.splashsports.com';

  const handleOnClose = () => {
    onClose();
    setSelectedItem('');
    setEmailInvites([]);
    setSplashInvites([]);
    setRYPInvites([]);
    setContests([]);
    setRYPPools([]);
  };

  const { mutate: handleSendInvites, isLoading: isSendingInvites } = useMutation({
    mutationFn: async () => {
      const extractIdsFromJSONArray = (array) =>
        array.map((json) => {
          const obj = JSON.parse(json);
          if (obj.id.toString().startsWith('ryp_')) {
            return obj.id.toString().slice(4);
          }
          return obj.id.toString();
        });

      const inviteCount = await sendInvites({
        inviteLink: `${inviteLinkURLOrigin}${contestId ? `/contest/${contestId}/detail` : '/'}?referral=${
          currentUser.id
        }`,
        contestId,
        emails: emailInvites,
        userIds: extractIdsFromJSONArray(splashInvites),
        contestIds: extractIdsFromJSONArray(contests),
        rypUserIds: extractIdsFromJSONArray(rypInvites),
        rypPoolIds: extractIdsFromJSONArray(rypPools),
      });
      return inviteCount;
    },
    onSuccess: (count) => {
      handleOnClose();

      reportEvent('Share > Send Invites', {
        count,
      });

      showNotification({
        title: t('invites.modal.success.title'),
        message: t('invites.modal.success.message__hasPlural', count),
        color: 'green',
        autoClose: 2500,
      });
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error: AxiosError<{ message?: string }>) => {
      const defaultMessage = t('invites.modal.error.message');
      const message = error?.response?.data?.message || defaultMessage;
      reportEvent('Share > Send Invites Error', {
        reason: message,
      });

      showNotification({
        title: t('invites.modal.error.title'),
        message,
        color: 'red',
        autoClose: 2500,
      });
    },
  });

  useEffect(() => {
    setSelectedMobileTab('people');
    queryClient.invalidateQueries({
      queryKey: ['invites/contestEntrants', selectedItem],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const {
    contestUsers: splashContestUsers,
    isLoading: isLoadingUsersByContest,
    loaderRef: splashLoaderRef,
    hasNextPage: splashHasNextPage,
  } = useFetchUsersByContest({
    selectedItem,
  });
  const {
    contestUsers: rypContestUsers,
    isLoading: isLoadingRYPUsersByContest,
    loaderRef: rypLoaderRef,
    hasNextPage: rypHasNextPage,
  } = useFetchRYPUsersByContest({
    selectedItem,
  });

  useEffect(() => {
    if (opened && emailOnly) {
      setSelectedItem('email');
      setSelectedMobileTab('email');
    }
  }, [emailOnly, opened]);

  const showUsersConfig = (): {
    users: EntrantUser[];
    loaderRef: MutableRefObject<undefined>;
    hasNextPage: boolean;
    isLoading: boolean;
  } => {
    const selectedItemInfo = safeJSONParse(selectedItem);

    if (selectedItemInfo?.id?.startsWith('ryp_')) {
      return {
        users: rypContestUsers,
        loaderRef: rypLoaderRef,
        hasNextPage: rypHasNextPage,
        isLoading: isLoadingRYPUsersByContest,
      };
    }

    return {
      users: splashContestUsers,
      loaderRef: splashLoaderRef,
      hasNextPage: splashHasNextPage,
      isLoading: isLoadingUsersByContest,
    };
  };
  const { users, loaderRef, isLoading } = showUsersConfig();

  const isTotalInvitesEmpty = totalInvites === 0;

  if (isTablet) {
    return (
      <Modal lockScroll centered opened={opened} onClose={handleOnClose} size={1280} zIndex={300}>
        <InviteHeader
          contestId={contestId}
          contestName={contestName}
          selectedMobileTab={selectedMobileTab}
          setSelectedMobileTab={setSelectedMobileTab}
          emailOnly={emailOnly}
        />
        <Stack className={classes.panelWrapper}>
          {selectedMobileTab === 'people' && (
            <PeoplePanel
              users={users}
              selectedItem={selectedItem}
              splashInvites={splashInvites}
              setSplashInvites={setSplashInvites}
              rypInvites={rypInvites}
              setRYPInvites={setRYPInvites}
              rypPools={rypPools}
              setRYPPools={setRYPPools}
              contests={contests}
              setContests={setContests}
              isSearching={isLoading}
              loaderRef={loaderRef}
            >
              <Divider />
              <Group position="right" align="center">
                <Button
                  fullWidth
                  size="lg"
                  radius="md"
                  onClick={() => handleSendInvites()}
                  loading={isSendingInvites}
                  disabled={isTotalInvitesEmpty}
                >
                  <Text color="black">{t('invites.modal.sendInvites')}</Text>
                </Button>
              </Group>
            </PeoplePanel>
          )}
          {selectedMobileTab === 'contests' && (
            <ContestsPanel
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              setSelectedMobileTab={setSelectedMobileTab}
            >
              <Divider />
              <Group position="right" align="center">
                <Button
                  fullWidth
                  size="lg"
                  radius="md"
                  onClick={() => handleSendInvites()}
                  loading={isSendingInvites}
                  disabled={isTotalInvitesEmpty}
                >
                  <Text color="black">{t('invites.modal.sendInvites')}</Text>
                </Button>
              </Group>
            </ContestsPanel>
          )}
          {selectedMobileTab === 'email' && <EmailPanel setEmailInvites={setEmailInvites} />}
          {totalInvites > 0 && (
            <TabletDrawer
              splashInvites={splashInvites}
              setSplashInvites={setSplashInvites}
              rypInvites={rypInvites}
              setRYPInvites={setRYPInvites}
              emailInvites={emailInvites}
              setEmailInvites={setEmailInvites}
              totalInvites={totalInvites}
              handleSendInvites={handleSendInvites}
              isLoading={isSendingInvites}
              rypPools={rypPools}
              setRYPPools={setRYPPools}
              contests={contests}
              setContests={setContests}
            />
          )}
        </Stack>
      </Modal>
    );
  }

  return (
    <Modal
      lockScroll
      centered
      opened={opened}
      onClose={handleOnClose}
      size={1280}
      zIndex={300}
      withCloseButton={false}
    >
      <InviteHeader
        contestId={contestId}
        contestName={contestName}
        selectedMobileTab={selectedMobileTab}
        setSelectedMobileTab={setSelectedMobileTab}
        emailOnly={emailOnly}
        onClose={handleOnClose}
      />
      <Group noWrap align="flex-start" mt="xl" h={SECTION_HEIGHT}>
        <InviteModalLeft
          emailOnly={emailOnly}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
        <InviteModalMiddle
          selectedItem={selectedItem}
          setEmailInvites={setEmailInvites}
          splashInvites={splashInvites}
          setSplashInvites={setSplashInvites}
          rypInvites={rypInvites}
          setRYPInvites={setRYPInvites}
          rypPools={rypPools}
          setRYPPools={setRYPPools}
          contests={contests}
          setContests={setContests}
          isLoading={isLoading}
          users={users}
          loaderRef={loaderRef}
        />
        <InviteModalRight
          emailInvites={emailInvites}
          setEmailInvites={setEmailInvites}
          splashInvites={splashInvites}
          setSplashInvites={setSplashInvites}
          rypInvites={rypInvites}
          setRYPInvites={setRYPInvites}
          rypPools={rypPools}
          setRYPPools={setRYPPools}
          contests={contests}
          setContests={setContests}
          emailOnly={emailOnly}
          totalInvites={totalInvites}
        />
      </Group>
      <Divider />
      <Group mt="lg" position="right" align="center">
        <Button
          size="lg"
          radius="md"
          onClick={() => handleSendInvites()}
          disabled={isTotalInvitesEmpty}
          loading={isSendingInvites}
        >
          <Text color="black">{t('invites.modal.sendInvites')}</Text>
        </Button>
      </Group>
    </Modal>
  );
}

export default InviteModal;
